import classNames from "classnames";
import Clickable from "components/Clickable";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import {
    ImageBackgroundHero,
    ImageBackgroundHeroCmsData,
} from "components/HeroBlocks/ImageBackgroundHero";
import { HoverZoom } from "components/HoverZoom";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";
import { CmsCtaImage, StreamfieldBlock } from "interfaces";

import styles from "./landscape-image-tiles.module.scss";

export type LandscapeImageTilesCmsData = {
    hero_tile: ImageBackgroundHeroCmsData;
    tiles: StreamfieldBlock<"cta_image", CmsCtaImage>[];
};

export type LandscapeImageTilesBlock = StreamfieldBlock<
    "landscape_image_tiles",
    LandscapeImageTilesCmsData
>;

export const LandscapeImageTiles: React.FC<LandscapeImageTilesCmsData> = ({
    hero_tile,
    tiles,
}) => {
    const width = useBlockWidth();
    return (
        <section
            className={classNames({
                [styles.container]: true,
                [styles.full]: width === "full",
            })}
        >
            <ul className={styles.landscapeImageTiles}>
                <li className={styles.landscapeImage}>
                    <ImageBackgroundHero {...hero_tile} isFirstBlock={false} />
                </li>
                {tiles.map(
                    (listItem: StreamfieldBlock<"cta_image", CmsCtaImage>) => (
                        <li key={listItem.id}>
                            <Clickable
                                cmsLink={listItem.value.link}
                                className={styles.tileClickable}
                            >
                                {listItem.value.image_block && (
                                    <HoverZoom>
                                        <CmsResponsiveImage
                                            cmsImage={
                                                listItem.value.image_block
                                            }
                                            cldSrcSet={[150, 295, 590, 885]}
                                            sizes="(max-width: 767px) 50vw, (max-width: 1024px) 25vw, (min-width: 1024px) 295px"
                                            cloudinaryProps={{
                                                aspectRatio: "1:1",
                                                crop: "fill",
                                            }}
                                            decorative={true}
                                        />
                                    </HoverZoom>
                                )}
                                {listItem.value.text}
                            </Clickable>
                        </li>
                    ),
                )}
            </ul>
        </section>
    );
};
export default LandscapeImageTiles;
